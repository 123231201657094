import { AxiosResponse } from 'axios';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import Instance from './instance';

const instance: any = new Instance().getAxiosIns();

class ChannelsAPI {
    public static getChannels(brandId: string, limit: number, offset: number): Promise<AxiosResponse<IChannelsRespponse>> {
        return instance.get(`/brands/${brandId}/channels`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static createChannel(brandId: string, body: {name: string, wazzup_id: string}): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/channels`, body);
    }
}

export default ChannelsAPI;
