const prepareURL = () => {
    
    const host = window.location.host;

    return 'https://ai.chat.test-loyalty.ru/api';

    // const API_HOST = window.extended?.REACT_MAIN_BACKEND

    // return `http://${API_HOST}/api`;
};

export default prepareURL();
