import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import Dialogues from 'src/pages/dialogues';
import Brands from 'src/pages/brands';
import BrandPage from 'src/pages/brands/brandPage';
import Auth from 'src/pages/auth';
import Registaration from 'src/pages/registration';
import DialoguePage from 'src/pages/dialogues/dialoguePage';

const Router = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/' element={<Navigate to='/brands' />} />

                <Route path='/brands' element={<Brands />} />
                <Route path='/brand/:brandId?' element={<BrandPage />} />
                <Route path='/brand/:brandId/channels/:channelId' element={<Dialogues />} />
                <Route path='/brand/:brandId/channels/:channelId/dialogues/:dialogueId' element={<DialoguePage />} />
            </Route>

            <Route path='/auth' element={<Auth />} />
            <Route path='/registration' element={<Registaration />} />
        </Routes>
    );
};

export default Router;
