import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import ChannelsAPI from 'src/api/ChannelsAPI';
import notificationActions from 'src/store/actions/notificationActions';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import IChannel from 'src/models/IChannel';

interface IProps {
    tokens: string;
}

const Channels = (props: IProps) => {
    const [name, setName] = useState<string>('');
    const [wazzup_id, setWazzup_id] = useState<string>('');
    const [channels, setChannels] = useState<IChannel[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getChannels = (limit: number, offset: number) => {
        if (brandId) {
            ChannelsAPI.getChannels(brandId, limit, offset)
                .then(response => {
                    if (response.status <= 204) {
                        setChannels(response.data.channels);
                        setCount(response.data.amount);
                    }
                })
        }
    }

    const handleSaveChannel = () => {
        if (brandId) {
            ChannelsAPI.createChannel(brandId, {
                name: name,
                wazzup_id,
            })
            .then(response => {
                if (response.status <= 204) {
                    setName('');
                    setWazzup_id('');
                    getChannels(10, 0);
                    setPage(1);
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                } else {
                    dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                }
            })
            .catch(() => {
                dispatch(notificationActions.setMessage({message: 'Ошибка'}));
            })
        }
    }

    const handleClick = (id: string) => {
        navigate(`channels/${id}`)
    }

    useEffect(() => {
        if (brandId) {
            getChannels(10, 0);
        }
    }, [])

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <h4>Добавить канал</h4>
                <div className={styles.inputContainer}>
                    <CustomInput
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder='Название'
                    />
                    <CustomInput
                        value={wazzup_id}
                        onChange={(e) => setWazzup_id(e.target.value)}
                        placeholder='Wazzup ID'
                    />
                    <MyButton 
                        disabled={!name || !wazzup_id} 
                        handleClickProps={handleSaveChannel}
                    >
                        Сохранить
                    </MyButton>
                </div>
            </div>
            <div className={styles.tokens}>
                Токены: {props.tokens}
            </div>
            <div>
                <table>
                    <thead>
                        <tr>
                            <th>Название</th>
                            <th>ID</th>
                            <th>AMO</th>
                            <th>Тип</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {channels.map((item) => (
                            <tr className={styles.tableTr} key={item.id} onClick={() => handleClick(item.id)}>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.id}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.amo_is_enabled ? 'вкл' : 'выкл'}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.chat_type}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <PaginationComponent
                    count={count}
                    limit={10}
                    page={page}
                    setPage={setPage}
                    onChange={getChannels}
                />
            </div>
        </div>
    )
}

export default Channels;
