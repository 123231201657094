import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import AuthAPI from 'src/api/AuthAPI';
import notificationActions from 'src/store/actions/notificationActions';
import storageKeys from 'src/utils/storageKeys';

const Auth = () => {
    const [email, setEmail] = useState<string>('');
    const [isErrorUsername, setIsErrorUsername] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [isErrorPassword, setIsErrorPassword] = useState<boolean>(false);

    const [isVisionResendEmail, setIsVisionResendEmail] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkEmailReady = () => {
        const res = !!email;
        setIsErrorUsername(!res);
        return res;
    };

    const checkPasswordReady = () => {
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/;
        // const res = passwordRegex.test(password);
        const res = password.length > 7;
        setIsErrorPassword(!res);
        return res;
    };

    const checkFieldsReady = () => {
        const resUsername = checkEmailReady();
        const resPass = checkPasswordReady();
        if (resUsername && resPass) return true;
        else return false;
    };

    const sendReq = () => {
        AuthAPI.auth(email, password)
            .then(response => {
                if (response.status <= 204) {
                    localStorage.setItem(storageKeys.STORAGE_TOKEN_KEY_NAME, response.data.access_token)
                    localStorage.setItem(storageKeys.STORAGE_REFRESH_TOKEN_KEY_NAME, response.data.refresh_token)
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    navigate('/brands')
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch(err => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    };

    const handleChangeEmail = (value: string) => {
        const reg = /\s/;

        if (reg.test(value)) {
            setEmail(email);
        } else {
            setEmail(value);
        }
    };

    const handleChangePassword = (value: string) => {
        const reg = /\s/;

        if (reg.test(value)) {
            setPassword(password);
        } else {
            setPassword(value);
        }
    };

    const handleSubmit = () => {
        if (checkFieldsReady()) {
            sendReq()
        }
    };

    return (
        <div className={styles.wrapperAuth}>
            <div key='auth' className={styles.formAuth}>
                <span className={styles.title}>Вход</span>

                <CustomInput
                    placeholder='Почта'
                    value={email}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                    name='email'
                    error={isErrorUsername}
                    errorMessage='Некорректно заполнено поле'
                />

                <CustomInput
                    placeholder='Пароль'
                    type='password'
                    value={password}
                    onChange={(e) => handleChangePassword(e.target.value)}
                    name='password'
                    error={isErrorPassword}
                    errorMessage='Некорректно заполнено поле'
                />

                <MyButton
                    children='Войти'
                    handleClickProps={handleSubmit}
                    className={styles.authBtn}
                />

                <div className={styles.underText}>
                    {/* <span className={styles.leftText}>Забыли пароль?</span> */}
                    <div onClick={() => (window.location.pathname = '/registration')}>
                        <span className={styles.rightText}>Зарегистрироваться</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth
