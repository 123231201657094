import { AxiosResponse } from 'axios';
import Instance from './instance';
import IPush from 'src/models/IPush';

const instance: any = new Instance().getAxiosIns();

class PushesAPI {
    public static getPushes(id: string): Promise<AxiosResponse<IPush[]>> {
        return instance.get(`/brands/${id}/pushes`);
    }
    
    public static createPushes(id: string, interval: number): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${id}/pushes`, {
            interval,
        });
    }

    public static deletePushes(id: string, pushId: string): Promise<AxiosResponse<any>> {
        return instance.delete(`/brands/${id}/pushes/${pushId}`);
    }
}

export default PushesAPI;
