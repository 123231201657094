import axios, { AxiosResponse } from 'axios';
import IRegistrationRequest from 'src/models/request/IRegistrationRequest';
import axiosConfig from './axiosConfig';

class AuthAPI {
    public static register(data: IRegistrationRequest): Promise<AxiosResponse<any>> {
        return axiosConfig.post(`/auth/sign-up`, data);
    }

    public static auth(email: string, password: string): Promise<AxiosResponse<any>> { 
        return axiosConfig.post(`/auth/login`, {
            email,
            password
        });
    }
}

export default AuthAPI;
