//import { Status } from './../pages/clients/funnel/dragAndDrop/types/index';
import axios, { AxiosInstance, AxiosError } from 'axios';

import jwtDefaultConfig from '../jwt/jwtDefaultConfig';
import storageKeys from 'src/utils/storageKeys';
import baseUrlConstant from '../utils/baseUrlConstant';

export default class Instance {
    // Will be used by this service for making API calls
    axiosIns: AxiosInstance | null = null;

    // For Refreshing Token
    isAlreadyFetchingAccessToken = false;

    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig };

    // For Refreshing Token
    subscribers: any = [];

    constructor() {
        this.axiosIns = axios.create({
            baseURL: baseUrlConstant,
            validateStatus: (status) => status < 500,
            withCredentials: false,
        });

        // Request Interceptor
        this.axiosIns.interceptors.request.use(
            async (config) => {
                // Get token from localStorage
                const accessToken = await this.getToken();

                // If token is present add it to request's Authorization Header
                if (accessToken) {
                    // eslint-disable-next-line no-param-reassign
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                    // config.headers.common['API-Key'] = accessToken;
                }
                return config;
            },
            (error: any) => {
                const err = error as AxiosError;
                // console.log('request error - ', err.response);
                return Promise.reject(err);
            },
        );

        // Add request/response interceptor
        this.axiosIns.interceptors.response.use(
            (response: any) => {
                const { config } = response;
                const originalRequest = config;

                // update token for edit info user

                // if (status === 401) {
                if (response && response.status === 401) {
                    window.location.pathname = '/auth';
                    localStorage.clear();
                    return
                    if (!this.isAlreadyFetchingAccessToken) {
                        if (config.url === 'auth/login') {
                            return response;
                        }
                        this.isAlreadyFetchingAccessToken = true;

                        this.refreshToken()
                            .then((r) => {
                                if (r.status > 201) {
                                    window.location.pathname = '/auth';
                                    localStorage.clear();

                                    return;
                                }

                                // Update accessToken in localStorage
                                this.setToken(r.data.access_token, r.data.refresh_token);

                                this.onAccessTokenFetched(r.data.access_token);
                                this.isAlreadyFetchingAccessToken = false;
                            })
                            .catch((err) => {
                                throw err;
                            });
                    }
                    const retryOriginalRequest = new Promise((resolve) => {
                        this.addSubscriber((accessToken: string) => {
                            // Make sure to assign accessToken according to your response.
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                            this.axiosIns ? resolve(this.axiosIns(originalRequest)) : resolve({});
                        });
                    });
                    return retryOriginalRequest;
                }
                if (config.status >= 500) {
                    //Add error handling
                    // console.log('response - ', response);
                }
                return response;
            },
            (error: any) => {
                //const err = error as AxiosError;
                // console.log('response error = ', err.response);
                // console.log('response error = ', error);
                const { config, response } = error;
                const originalRequest = config;

                // if (status === 401) {
                if (response && response.status === 401) {
                    if (!this.isAlreadyFetchingAccessToken) {
                        this.isAlreadyFetchingAccessToken = true;
                        this.refreshToken()
                            .then((r) => {
                                if (r.status > 203) {
                                    // navigate('login');
                                    // console.log('refreshToken - ');
                                    localStorage.clear();
                                    return;
                                }
                                this.isAlreadyFetchingAccessToken = false;

                                // Update accessToken in localStorage
                                this.setToken(r.data.access_token, r.data.refresh_token);

                                this.onAccessTokenFetched(r.data.access_token);
                            })
                            .catch(() => {
                                // navigate('login');
                            });
                    }
                    const retryOriginalRequest = new Promise((resolve) => {
                        this.addSubscriber((accessToken: string) => {
                            // Make sure to assign accessToken according to your response.
                            // Change Authorization header
                            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
                            this.axiosIns ? resolve(this.axiosIns(originalRequest)) : resolve({});
                        });
                    });
                    return retryOriginalRequest;
                }

                if (response && response.status === 504) {
                    console.log('error with 504:', error.data);
                    localStorage.clear();
                    window.location.pathname = '/auth';
                }
                return Promise.reject(error);
            },
        );
    }

    onAccessTokenFetched(accessToken: string) {
        this.subscribers = this.subscribers.filter((callback: (token: string) => any) => callback(accessToken));
    }

    addSubscriber(callback: (accessToken: string) => void) {
        this.subscribers.push(callback);
    }

    async refreshToken() {
        const ax = axios.create({
            baseURL: baseUrlConstant,
            validateStatus: (status) => status < 500,
            withCredentials: false,
        });

        ax.defaults.headers.Authorization = `${this.jwtConfig.tokenType} ${localStorage.getItem(storageKeys.STORAGE_REFRESH_TOKEN_KEY_NAME)}`
        return ax.post('auth/token/refresh');
    }

    getToken() {
        return localStorage.getItem(storageKeys.STORAGE_TOKEN_KEY_NAME);
    }

    setToken(access: string, refresh: string) {
        localStorage.setItem(storageKeys.STORAGE_TOKEN_KEY_NAME, access);
        localStorage.setItem(storageKeys.STORAGE_REFRESH_TOKEN_KEY_NAME, refresh);
    }

    getAxiosIns() {
        return this.axiosIns;
    }
}
