import { Outlet, useNavigate } from 'react-router-dom';
import Header from '../ui/header';
import styles from './style.module.scss'
import { useEffect } from 'react';
import isAuth from 'src/utils/isAuth';

const Layout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuth()) {
            navigate('/auth');
        }
    }, [])

    return (
        <div className={styles.layout}>
            <Header/>
            <Outlet />
        </div>
    );
};

export default Layout;
