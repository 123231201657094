import { MyButton } from '../myButton';
import styles from './style.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Header = () => {
    let location = useLocation();

    const navigate = useNavigate();

    const { brandId, channelId } = useParams();

    const handleExit = () => {
        localStorage.clear();
        navigate('/auth')
    }

    return (
        <div className={styles.header}>
            <div>
                <span onClick={() => navigate('/brands')} className={styles.link}>Список брендов</span>
                {location.pathname !== '/brands' && 
                    <>
                        <span>/ </span>
                        <span
                            onClick={() => navigate(`/brand/${brandId}`)} 
                            className={styles.link}
                        >
                            Бренд
                        </span>
                    </>
                }
                {location.pathname.includes('channels') && 
                    <>
                        <span>/ </span>
                        <span 
                            className={styles.link}
                            onClick={() => navigate(`/brand/${brandId}/channels/${channelId}`)} 
                        >
                            Канал
                        </span>
                    </>
                }
                {location.pathname.includes('dialogues') && 
                    <>
                        <span>/ </span>
                        <span 
                            className={styles.link}
                        >
                            Диалог
                        </span>
                    </>
                }
            </div>
            <MyButton
                children="Выйти"
                handleClickProps={handleExit}
            />
        </div>
    )
}

export default Header;
